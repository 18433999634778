export const checkboxComponentTheme = {
	Checkbox: {
		baseStyle: {
			label: {
				color: 'gray.800',
				fontWeight: '400'
			},
			control: {
				border: '1px solid',
				borderColor: 'gray.300'
			}
		},
		sizes: {
			lg: {
				label: {
					fontSize: 'sm',
					lineHeight: 'sm'
				}
			}
		}
	}
}
