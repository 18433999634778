import { toastStylesTheme } from '@ui'

const baseStyles = {
  'body, html': {
    bgColor: 'base.1',
    m: 0,
    p: 0,
    fontFamily: 'heading'
  },
  ...toastStylesTheme
}

export default baseStyles
