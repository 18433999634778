export const VIEW_CONTENT_MAP = {
	signIn: {
		title: 'sign-in'
	},
	signUp: {
		title: 'sign-up'
	},
	verify: {
		title: 'verification'
	},
	signInError: {
		title: ''
	},
	otpError: {
		title: ''
	}
}